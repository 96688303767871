import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import FormInputSelect from "../../../components/form/FormInputSelect";
import { FormInputText } from "../../../components/form/FormInputText";
import { FormInputRadio } from "../../../components/form/FormInputRadio";
import { useForm } from "react-hook-form";
import { countries } from "../../../data/static/countries";
import { RoundButton } from "../../../_styles/StyledButtons";
import { useStore } from "../../../stores/store";
import { ClientDetailsCreateDto, ClientDetailsDto } from "../../../data/models/client";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getGenderOptions } from "../../../data/static/user";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { contactAgencySchema } from "../../../_validators/schemas/contactAgency.schema";


interface ClientDetailsProps {
    back: () => void;
    display: boolean
}

interface ContactAgencyFormProps {
    control: any;
}

function ContactAgencyForm({ control }: ContactAgencyFormProps) {
    const { t } = useTranslation();

    return (
        <Grid container columnSpacing={1}>
            <Grid item xs={12}>
                <FormInputText
                    name="agencyName"
                    control={control}
                    label={`${t("CLIENT_AGENCY_NAME")} *`}
                />
            </Grid>
            <Grid item xs={6}>
                <FormInputText
                    name="agencyEmail"
                    control={control}
                    label={`${t("CLIENT_AGENCY_EMAIL")} *`}
                />
            </Grid>
            <Grid item xs={6}>
                <FormInputText
                    name="agencyPhone"
                    control={control}
                    label={`${t("CLIENT_AGENCY_PHONE")}`}
                />
            </Grid>
        </Grid>
    );
}

function ClientDetails({ back, display = false }: ClientDetailsProps) {
    const { t } = useTranslation();
    const { clientStore, institutionStore } = useStore();
    const navigate = useNavigate();
    const { _clientCreateId, createClientDetails, getClientDetails } = clientStore;
    const { selectedUserInstitution } = institutionStore;
    const { id } = useParams();
    const { control, handleSubmit, setValue, watch } = useForm({
        resolver: yupResolver(contactAgencySchema())
    });
    const [loading, setLoading] = useState(false);
    const [client, setClient] = useState<ClientDetailsDto | null>(null);
    const shouldContactAgency = watch("contactWithReferringAgency");

    const confirmOptions = [
        { value: "true", label: t("GENERAL_YES") },
        { value: "false", label: t("GENERAL_NO") },
    ];

    useEffect(() => {
        const fetchData = async () => {
            if (id) {
                getClientDetails(id, selectedUserInstitution!.institutionId).then(client => {
                    setClient(client);
                    setValue("contactWithReferringAgency", client.contactWithReferringAgency);
                    setValue("helperGenderPreference", client.helperGenderPreference ?? "none");
                    setValue("needsChildCare", client.needsChildCare);
                    setValue("agencyName", client.contactAgency?.agencyName ?? "");
                    setValue("agencyEmail", client.contactAgency?.agencyEmail ?? "");
                    setValue("agencyPhone", client.contactAgency?.agencyPhone ?? "");
                });
            }
        }

        fetchData();
    }, [getClientDetails, id, setValue]);

    const onSubmit = async (data: any) => {
        const clientDetails: ClientDetailsCreateDto = {
            ...data,
            id: _clientCreateId,
            needsChildCare: String(data.needsChildCare) === "true" ? true : false,
            contactWithReferringAgency: String(data.contactWithReferringAgency) === "true" ? true : false,
            contactAgency: shouldContactAgency === "true" || shouldContactAgency === true ? {
                agencyName: data.agencyName,
                agencyEmail: data.agencyEmail,
                agencyPhone: data.agencyPhone,
            } : null,
        }

        try {
            setLoading(true);
            if (client) {
                await clientStore.updateClientDetails(clientDetails);
            } else {
                await createClientDetails(clientDetails);
            }
            setLoading(false);
            toast.success(client ? t("TOAST_CLIENT_UPDATE") : t("TOAST_CLIENT_CREATE"));
        } catch (error) {
            setLoading(false);
            throw error;
        }
        navigate("/users");
    }

    return (
        <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ display: display ? 'block' : 'none' }}
        >
            <Grid container columnSpacing={1} marginTop="1rem" padding="1rem 3rem">
                <Grid item xs={12} lg={6}>
                    <FormInputSelect
                        name="helperGenderPreference"
                        control={control}
                        label={t("CLIENT_HELPER")}
                        options={getGenderOptions(t)}
                        valueKey={"value"}
                        labelKey={"label"}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormInputSelect
                        name="needsChildCare"
                        control={control}
                        label={t("CLIENT_CHILDCARE")}
                        options={confirmOptions}
                        valueKey={"value"}
                        labelKey={"label"}
                        defaultValue={false}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box width="100%" display="flex" alignItems="center">
                        <Typography sx={{ marginRight: "2rem" }}>
                            {t("CLIENT_AGENCY")}
                        </Typography>
                        <FormInputRadio
                            name="contactWithReferringAgency"
                            control={control}
                            options={confirmOptions}
                            defaultValue={shouldContactAgency}
                        />
                    </Box>
                </Grid>
                {String(shouldContactAgency) === "true" && <Grid item xs={12}>
                    <ContactAgencyForm control={control} />
                </Grid>}
                <Box
                    width="100%"
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    marginBottom="2rem"
                >
                    <RoundButton
                        variant="text"
                        sx={{ color: "#2a2a2a", marginRight: '1rem' }}
                        onClick={back}
                    >
                        <Typography fontSize="13px">{t("GENERAL_BACK")}</Typography>
                    </RoundButton>
                    <RoundButton
                        type="submit"
                        variant="contained"
                        sx={{ mt: 2, mb: 2 }}
                        disabled={loading}
                        title="Submit"
                        data-testid="submit-button"
                    >
                        {loading
                            ? <CircularProgress size={25} />
                            : <Typography fontSize="13px">{t("GENERAL_SUBMIT")}</Typography>
                        }
                    </RoundButton>
                </Box>
            </Grid>
        </Box>
    );
}

export default ClientDetails;