import dayjs from "dayjs";
import { CalendarEvent } from "../../data/models/event";
import { parseRrule } from "../eventRecurrence";
import { TaskPriority } from "../../data/models/client";
import { ResponsiblePersonDto, UserDetailsDto } from "../../data/models/user";
import { isUUID } from "../utils";

/**
 * Retrieves event details including date, time, and recurrence information.
 * @param {CalendarEvent} event - The calendar event for which to retrieve details.
 * @returns {string[]} Returns an array of strings containing event details.
 */
export const getEventDetails = (event: CalendarEvent): string[] => {
    const formatDate = (date: Date) => dayjs(date).format("DD MMM, YYYY");
    const formatTime = (time: Date) => dayjs(time).format("HH:mm");

    const recurrence = parseRrule(event.recurrence, event.start, event.end);

    const { start, end } = event;
    const frequency = recurrence.type ?? 9;
    const endDate = frequency !== 9 ? formatDate(recurrence.endDate!) : null;

    let message: string[] = [
        `Date: From ${formatDate(start)} to ${formatDate(end)}\n`,
    ];
    message.push(
        event.isAllDay
            ? "Time: All day\n"
            : `Time: From ${formatTime(start)} to ${formatTime(end)}\n`
    );
    if (frequency === 9) {
        return message;
    } else if (frequency === 3) {
        const interval =
            recurrence.interval! > 1 ? `${recurrence.interval} days` : "day";
        message.push(`Repeating: Every ${interval}, until ${endDate}\n`);
    } else if (frequency === 1) {
        const interval =
            recurrence.interval! > 1 ? `${recurrence.interval} months` : "month";
        const monthday = recurrence.byMonthDay!;
        const postfix = getDatePostfix(monthday);
        message.push(
            `Repeating: Every ${interval}, on the ${monthday}${postfix} until ${endDate}\n`
        );
    } else if (frequency === 2) {
        const interval =
            recurrence.interval! > 1 ? `${recurrence.interval} weeks` : "week";
        const days = formatDays(recurrence.byDays!);
        message.push(`Repeating: Every ${interval} on ${days} until ${endDate}\n`);
    }

    return message;
};

export function setBasicInfoValues(client: UserDetailsDto | null, setValue: Function, roles: string[]) {
    const userLanguages = client?.userLanguages?.map((l) => l.alpha2);
    const responsiblePerson: ResponsiblePersonDto[] = assignResponsibleUsers(client?.responsibleUsers || []);

    if (roles.length > 0) {
        setValue("roleIds", roles ?? []);
    }
    if (client?.hasAccount) {
        setValue("platformLanguageAlpha2", client?.platformLanguage?.alpha2 ?? "en");
    }
    setValue("firstName", client?.firstName ?? "");
    setValue("lastName", client?.lastName ?? "");
    setValue("gender", client?.gender ?? "");
    setValue("isUnaccompaniedMinor", client?.isUnaccompaniedMinor ?? false);
    setValue("isIlliterate", client?.isIlliterate ?? false);
    setValue("originCountry", client?.originCountry ?? "");
    setValue("birthdate", client ? dayjs(client.birthdate).toDate() : null);
    setValue("email", client && isUUID(client.email) ? "" : client?.email);
    setValue("phoneNumber", client?.phoneNumber ?? "");
    setValue("country", client?.country ?? "");
    setValue("city", client?.city ?? "");
    setValue("hasAccount", client?.hasAccount ?? false);
    setValue("street", client?.street ?? "");
    setValue("zip", client?.zip ?? "");
    setValue("number", client?.number ?? "");
    setValue("notes", client?.notes ?? "");
    setValue("residenceStatus", client?.residenceStatus ?? "");
    setValue("userLanguages", userLanguages ?? []);
    setValue("preferredCommunicationChannel", client?.preferredCommunicationChannel ?? null);
    setValue("communicationLanguage", client?.communicationLanguage ?? "en")
    setValue("responsibleUsers", responsiblePerson);
    setValue("ahvNumber", client?.ahvNumber ?? "");
    setValue("zemisNumber", client?.zemisNumber ?? "");
    setValue("accomodation", client?.accomodation ?? "");
    setValue("healthInsurance", client?.healthInsurance ?? "");
}

export function priorityValue(priority: TaskPriority): number {
    switch (priority) {
        case TaskPriority.LOW:
            return 2;
        case TaskPriority.MEDIUM:
            return 1;
        case TaskPriority.HIGH:
            return 0;
        default:
            return 0;
    }
}

export function assignResponsibleUsers(responsibleUsers: ResponsiblePersonDto[]): ResponsiblePersonDto[] {
    const reponsibleUserList: ResponsiblePersonDto[] = []
    if (responsibleUsers.length > 0) {
        responsibleUsers.forEach(element => {
            reponsibleUserList.push(element);
        });
    }

    return reponsibleUserList;
}

/**
 * Formats an array of week days.
 * @param {string[]} weekDays - The array of week days to format.
 * @returns {string} Returns a formatted string containing the week days separated by commas.
 */
function formatDays(weekDays: string[]): string {
    const dayMap: { [key: string]: string } = {
        MO: "Monday",
        TU: "Tuesday",
        WE: "Wednesday",
        TH: "Thursday",
        FR: "Friday",
        SA: "Saturday",
        SU: "Sunday",
    };

    const formattedDays: string[] = [];

    for (const day of weekDays) {
        const formattedDay = dayMap[day] || "Invalid day";
        formattedDays.push(formattedDay);
    }

    return formattedDays.join(", ");
}

/**
 * Gets the postfix for a date number to denote its position (e.g., "st" for 1st, "nd" for 2nd).
 * @param {number} dateNumber - The number of the date to get the postfix for.
 * @returns {string} Returns the postfix string.
 */
function getDatePostfix(dateNumber: number): string {
    if (dateNumber >= 11 && dateNumber <= 13) {
        return "th";
    }

    const lastDigit = dateNumber % 10;
    let postfix: string;

    switch (lastDigit) {
        case 1:
            postfix = "st";
            break;
        case 2:
            postfix = "nd";
            break;
        case 3:
            postfix = "rd";
            break;
        default:
            postfix = "th";
            break;
    }

    return postfix;
}
