import {
    Box,
    Grid,
    Typography,
} from "@mui/material";
import FormInputDate from "../../../components/form/FormInputDate";
import FormInputSelect from "../../../components/form/FormInputSelect";
import { intervals } from "../../../data/static/booking";
import { getDaysInMonth } from "date-fns";
import { useEffect, useState } from "react";
import { recurrenceWeekDays } from "../../../data/static/weekDays";
import FormInputChipSelect from "../../../components/form/FormInputChipSelect";
import { rrulestr } from "rrule";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import RepeatOutlinedIcon from "@mui/icons-material/RepeatOutlined";
import { getRecurrenceWeekDays } from "../../../utils/eventRecurrence";

const gridItemStyle = {
    display: "flex",
    justifyContent: "flex-start",
};

interface RecurrenceFormDialogProps {
    initialEndDate: Date;
    control: any;
    frequency: number | undefined;
    rrule?: string;
}

function RecurrenceFormDialog({
    initialEndDate,
    control,
    frequency,
    rrule,
}: RecurrenceFormDialogProps) {
    const { t } = useTranslation();
    const [selectedMonthDay, setSelectedMonthDay] = useState(1);
    const [selectedMonth, setSelectedMonth] = useState(initialEndDate);
    const pattern = rrule && rrule !== "no-repeat" ? rrulestr(rrule) : undefined;
    const getAvailableDays = (): number[] => {
        const daysInMonth = getDaysInMonth(selectedMonth);
        return Array.from({ length: daysInMonth }, (_, index) => index + 1);
    };
    const defaultWeekDays = rrule ? getRecurrenceWeekDays(rrule) : [];

    recurrenceWeekDays.forEach(item => {
        item.translatedName = t('RECURRENCE_' + item.label.toUpperCase());
    });
    useEffect(() => {
        if (selectedMonth < initialEndDate) {
            setSelectedMonth(initialEndDate);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialEndDate]);

    const monthDayOptions: { value: number; label: string }[] =
        getAvailableDays().map((day) => ({
            value: day,
            label: day.toString(),
        }));

    const handleMonthChange = (date: Date) => {
        setSelectedMonth(date);
        setSelectedMonthDay(1);
    };

    const onSelectDay = (e: any) => {
        setSelectedMonthDay(e.target.value);
    };

    if (frequency === 9) return null;

    return (
        <>
            <Grid xs={11.5} columnGap={2} sx={gridItemStyle} className="recurrence-content">
                <RepeatOutlinedIcon className="event-form-icon" sx={{ color: "#0000008a", alignSelf: 'center' }} />
                <Grid item xs={6.7} sm={6} md={3.2} lg={3.2}>
                    <Box className="recurrence-label-content">
                        <Typography className="recurrence-input-label">{t("EVENT_REC_END")}</Typography>
                    </Box>
                    <FormInputDate
                        name="recurrence.endDate"
                        date={
                            pattern
                                ? pattern.options.until
                                : dayjs(initialEndDate).add(1, "month").toDate()
                        }
                        dateLimit={initialEndDate}
                        control={control}
                        onSelect={handleMonthChange}
                    />
                </Grid>
                <Grid item xs={6.7} sm={6} md={2.2} lg={2.2}>
                    <Box display={"flex"} alignItems={"center"}>
                        <Typography className="recurrence-input-label">{t("EVENT_REC_INTERVAL")}</Typography>
                    </Box>
                    <FormInputSelect
                        name="recurrence.interval"
                        defaultValue={pattern ? pattern.options.interval : intervals[0].id}
                        options={intervals}
                        control={control}
                        labelKey={"name"}
                        valueKey={"id"}
                    />
                </Grid>
                {frequency === 1 &&
                    <Grid item xs={6.7} sm={8} md={2.2} lg={2.4}>
                        <Box display={"flex"} alignItems={"center"}>
                            <Typography className="recurrence-input-label">{t("EVENT_REC_DAY")}</Typography>
                        </Box>
                        <FormInputSelect
                            name={"recurrence.byMonthDay"}
                            control={control}
                            labelKey={"label"}
                            valueKey={"value"}
                            options={monthDayOptions}
                            defaultValue={
                                pattern && pattern.options.bymonthday.length > 0
                                    ? pattern.options.bymonthday[0].toString()
                                    : selectedMonthDay.toString()
                            }
                            onSelect={onSelectDay}
                        />
                    </Grid>}
                {frequency === 2 &&
                    <Grid item xs={6.7} sm={6} md={5} lg={5}>
                        <Box className="recurrence-label-content">
                            <Typography className="recurrence-input-label">{t("EVENT_REC_WEEKDAY")}</Typography>
                        </Box>
                        <FormInputChipSelect
                            options={recurrenceWeekDays}
                            labelKey={"translatedName"}
                            valueKey={"tag"}
                            defaultValues={defaultWeekDays ?? []}
                            name={"recurrence.byDays"}
                            control={control}
                        />
                    </Grid>
                }
            </Grid>
        </>
    );
}

export default RecurrenceFormDialog;
