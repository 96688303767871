import * as Yup from "yup";

export const contactAgencySchema = () => {
    const schema = Yup.object().shape({
        agencyName: Yup.string().when("contactWithReferringAgency", {
            is: (contactWithReferringAgency: string) =>
                contactWithReferringAgency === "true",
            then: (schema) =>
                schema.trim().required("Please provide the agency's name"),
        }),
        agencyEmail: Yup.string().when("contactWithReferringAgency", {
            is: (contactWithReferringAgency: string) =>
                contactWithReferringAgency === "true",
            then: (schema) =>
                schema.email("Invalid email address").trim().required("Please provide the agency's email"),
        }),
    });

    return schema;
};
export const getDynamicSchema = (fieldName: string) => {
    return Yup.object().shape({
        [fieldName]: fieldName === "agencyEmail"
            ? Yup.string().email("Invalid email address").trim()
            : Yup.mixed().nullable(),
    });
};