import { Box, Grid, Typography } from "@mui/material";

import { useState } from "react";

import ClientProcessTable from "./ClientProcessTable";
import { RoundButton } from "../../../_styles/StyledButtons";
import ClientSessionForm from "./ClientSessionForm";
import { useTranslation } from "react-i18next";

interface ClientProcessProps {
    next: () => void;
    back: () => void;
    display: boolean;
}

function ClientProcess({ next, back, display }: ClientProcessProps) {
    const { t } = useTranslation();
    const [isAdded, setIsAdded] = useState(false);
    const [showMsg, setShowMsg] = useState(false);

    const handleNext = () => {
        if (isAdded) {
            next();
        } else {
            setShowMsg(true);
        }
    }

    const handleBack = () => {
        setShowMsg(false);
        back();
    }

    return (
        <Box sx={{ display: display ? "block" : "none" }}>
            <Grid
                container
                columnSpacing={1}
                marginTop="1rem"
                marginBottom="5px"
            >
                <Grid item xs={12}>
                    <ClientProcessTable setAdded={setIsAdded} added={isAdded} showInfo={showMsg} setShowInfo={setShowMsg} />
                </Grid>
                <Grid item xs={12}>
                    <ClientSessionForm visible={isAdded} />
                </Grid>
                <Grid
                    item
                    xs={12}
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    margin={"1rem 3rem"}
                >
                    <RoundButton
                        variant="text"
                        color="inherit"
                        onClick={handleBack}
                        sx={{ marginRight: "1rem" }}
                    >
                        <Typography fontSize={"13px"}>{t("GENERAL_BACK")}</Typography>
                    </RoundButton>
                    <RoundButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                    >
                        <Typography fontSize={"13px"}>{t("GENERAL_NEXT")}</Typography>
                    </RoundButton>
                </Grid>
            </Grid>
        </Box>
    );
}

export default ClientProcess;