import {
    Box
} from "@mui/material";
import ClientSurveyTable from "./ClientSurveyTable";

import { useContext, useState } from "react";
import { observer } from "mobx-react-lite";

import { useStore } from "../../../../../stores/store";
import {
    SessionTimeline,
} from "../../../../../data/models/session";
import SessionStatusCards from "./SessionStatusCards";
import SessionDashboardHeader from "./SessionDashboardHeader";
import Sidebar from "../../../../../components/sidebar/Sidebar";
import DashboardActions from "./DashboardActions";
import { SessionOverviewContext } from "./SessionOverview";

function SessionDashboard() {
    const { width } = useContext(SessionOverviewContext);
    const { sessionStore } = useStore();
    const {
        loadingTimelines,
        session
    } = sessionStore;

    const [selectedTimeline, setSelectedTimeline] =
        useState<SessionTimeline | null>(null);

    const onTimelineChange = (timeline: SessionTimeline) => {
        setSelectedTimeline(timeline);
    };

    const isActive =
        !!session?.sessionStartTime &&
        !!session?.timelineStartTime &&
        new Date(session.sessionStartTime) < new Date() &&
        new Date(session.timelineStartTime) < new Date();

    return (
        <Box className="table-container">
            <Sidebar />
            <Box className="session-stats">
                <Box sx={{ flexGrow: 3 }}>
                    <SessionDashboardHeader selectedTimeline={selectedTimeline} onTimelineChange={onTimelineChange} />
                    {width <= 1100 && <Box className="session-action-container">
                        <DashboardActions isActive={isActive} />
                    </Box>}
                    <Box className="session-events" sx={{ flexGrow: 1 }}>
                        <SessionStatusCards timeline={selectedTimeline} />
                    </Box>
                </Box>
            </Box>
            <Box display={"flex"} flexGrow={1}>
                <ClientSurveyTable
                    timeline={selectedTimeline}
                    loading={loadingTimelines}
                />
            </Box>
        </Box>
    );
}

export default observer(SessionDashboard);
