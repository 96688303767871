import { makeAutoObservable, runInAction } from "mobx";
import { Totp } from "../data/models/user";
import agent from "../utils/agent";
import { store } from "./store";

export default class TotpStore {
    loading: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    adminConfirmation = async (totpRequest: Totp, token?: string | null) => {
        store.loadingStore.startLoading(this.adminConfirmation);
        this.loading = true;
        try {
            await agent.Auth.adminVerify(totpRequest, token);
            runInAction(() => this.loading = false)
            store.loadingStore.stopLoading(this.adminConfirmation);
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.adminConfirmation);
            throw error;
        }
    }

    qrCodeAuthorization = async (totpRequest: Totp, token?: string | null) => {
        store.loadingStore.startLoading(this.qrCodeAuthorization);
        this.loading = true;
        try {
            let user = await agent.Auth.qrCodeAuthorize(totpRequest, token);
            store.commonStore.setToken(user.token);
            await store.authStore.getCurrentUser();
            store.commonStore.userConfig();

            runInAction(() => {
                this.loading = false;
            })
            store.loadingStore.stopLoading(this.qrCodeAuthorization);
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.qrCodeAuthorization);
            throw error;
        }
    }

    generatePsk = async () => {
        store.loadingStore.startLoading(this.generatePsk);
        this.loading = true;
        try {
            let psk = await agent.Auth.generatePsk();
            runInAction(() => {
                this.loading = false;
            });
            store.loadingStore.stopLoading(this.generatePsk);

            return psk;
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.generatePsk);
            throw error;
        }
    }

    disableTwoFactor = async (totp: string) => {
        store.loadingStore.startLoading(this.disableTwoFactor);
        this.loading = true;
        try {
            await agent.Auth.disableTwoFactor(totp);
            runInAction(() => {
                runInAction(() => {
                    this.loading = false;
                });
            })
            store.loadingStore.stopLoading(this.disableTwoFactor);
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.disableTwoFactor);
            throw error;
        }
    }
}