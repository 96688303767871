import * as Yup from 'yup';

export const profileFormSchema = () => {
    const schema = Yup.object().shape({
        firstName: Yup.string().required('First name is required').trim(),
        lastName: Yup.string().required('Last name is required').trim(),
        email: Yup.string().email('Invalid email').required('Email is required').trim(),
        phoneNumber: Yup.string().required('Phone number is required').trim(),
        portalLanguage: Yup.string().required('Please select your portal language').trim(),
        communicationLanguage: Yup.string().required('Please select communication language').trim(),
        languages: Yup.array().min(1, "Please select at least one option"),
    });

    return schema;
}

export const forgotPasswordFormSchema = () => {
    const schema = Yup.object().shape({
        email: Yup.string().trim().email('Please enter a valid email address').required('Please enter your email'),
    });

    return schema;
}

export const resetPasswordFormSchema = () => {
    const schema = Yup.object().shape({
        newPassword: Yup.string().trim()
            .min(8, 'Password must be at least 8 characters long')
            .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
            .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
            .matches(/[0-9]/, 'Password must contain at least one number')
            .matches(
                /[!@#$%^&*(),.?":{}|<>]/,
                'Password must contain at least one special character'
            )
            .required('Password is required'),
        confirmPassword: Yup.string().trim()
            .oneOf([Yup.ref('newPassword')], 'Passwords must match')
            .required('Confirm password is required'),
    });

    return schema;
}