import { Box, Container, Step, StepLabel, Stepper } from "@mui/material";

import { useEffect, useState } from "react";

import ClientBasicInfo from "./ClientBasicInfo";
import ClientAvailability from "./ClientAvailability";
import ClientDetails from "./ClientDetails";
import "./styles.css";
import { useStore } from "../../../stores/store";
import ClientProcess from "./ClientProcess";
import { useTranslation } from "react-i18next";

function ClientForm() {
    const { t } = useTranslation();
    const { clientStore } = useStore();
    const { clearClientId } = clientStore;
    const [step, setStep] = useState(0);

    useEffect(() => {
        const unloadCallback = (event: any) => {
            event.preventDefault();
            event.returnValue = "";
            return "";
        };

        window.addEventListener("beforeunload", unloadCallback);
        return () => {
            window.removeEventListener("beforeunload", unloadCallback);
            clearClientId();
        };
    }, [clearClientId]);

    const onNextStep = () => {
        if (step === steps.length - 1) return;
        setStep(step + 1);
    };

    const onPreviousStep = () => {
        if (step === 0) return;
        setStep(step - 1);
    };

    const steps = [
        { label: t("CLIENT_BASIC_INFO") },
        { label: t("CLIENT_AVAILABILITY") },
        { label: t("CLIENT_PROCESS") },
        { label: t("CLIENT_DETAILS") },
    ];

    return (
        <Container
            maxWidth="md"
            className={`client-form-container`}
        >
            <Box className="client-stepper-container">
                <Stepper activeStep={step} alternativeLabel>
                    {steps.map((step) => (
                        <Step key={step.label}>
                            <StepLabel>{step.label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#fff",
                    borderRadius: "4px",
                    mb: "2rem"
                }}
            >
                <ClientBasicInfo next={onNextStep} display={step === 0} />
                <ClientAvailability
                    next={onNextStep}
                    back={onPreviousStep}
                    display={step === 1}
                />
                <ClientProcess
                    next={onNextStep}
                    back={onPreviousStep}
                    display={step === 2}
                />
                <ClientDetails back={onPreviousStep} display={step === 3} />
            </Box>
        </Container>
    );
}

export default ClientForm;
